import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "services/CRUDServices";
import { filterUrls } from "services/api.enums";

export const filterSettlementModel: AsyncThunk<{}, {}, {}> = createAsyncThunk(
  "settlement",
  async (body: any) => {
    // delete body.pageNumber
    const data = await filterTableDataAPI(
      filterUrls.settlement,
      body.pageNumber,
      { ...body, pageNumber: undefined, pageSize: undefined },
      true,
      10,
      false
    );

    return data.data;
  }
);

const initialState: any = {
  settlementData: [],
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const SettlementLists = createSlice({
  initialState: initialState,
  name: "settlement",
  reducers: {
    clearSettlement: (state) => {
      state.settlementData = [];
    },
    setFilterSettlement: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [filterSettlementModel.fulfilled as any]: (state, action) => {
      state.settlementData = action.payload;
      state.fetchPost = false;
    },
    [filterSettlementModel.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterSettlementModel.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilterSettlement, clearSettlement } = SettlementLists.actions;

export default SettlementLists.reducer;
