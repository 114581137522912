import { useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import React from "react";

type InputTextProps = {
  error?: any;
  values?: any;
  label?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  important?: boolean;
  readOnly?: boolean;
  classNames?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  wrapperClassName?: string;
  password?: boolean;
  leftIcon?: JSX.Element;
  maxLength?: number;
  handleKeyDown?:any
};
const InputText = React.forwardRef(
  (
    {
      classNames,
      label,
      name,
      handleChange,
      type = "text",
      important,
      error,
      placeholder,
      readOnly,
      values,
      wrapperClassName,
      password,
      leftIcon,
      maxLength,
      handleKeyDown
    }: InputTextProps,
    ref: any
  ) => {
    const [HidePassword, setHidePassword] = useState(false);

    // const handleKeyDown = (event) => {
    //   if (event.key === "Enter") {
    //     console.log("do validate",event.target.value);
    //   }
    // };

    return (
      <div className={`flex flex-col  Max-md:w-full  ${wrapperClassName} `}>
        <div
          className={`autocompleteWrapper ${classNames} ${
            error && "border-red"
          } ${readOnly && "opacity-40"} `}
        >
          <div
            className={`autocompleteLabel  ${error && "text-red"} top-[-17px]`}
          >
            {label}{" "}
            <span className="text-tomato font-extrabold text-lg h-4">
              {important ? "*" : " "}
            </span>
          </div>
          <input
            maxLength={maxLength}
            ref={ref}
            disabled={readOnly}
            className="autocompleteInput"
            name={name}
            value={values}
            onChange={handleChange}
            type={password ? (!HidePassword ? "password" : type) : type}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
          />
          {password && (
            <div
              className="text-darkBorder cursor-pointer pr-3"
              onClick={() => setHidePassword(!HidePassword)}
            >
              {!HidePassword ? <BiHide size={20} /> : <BiShow size={20} />}
            </div>
          )}
          {leftIcon && <span className="pr-3">{leftIcon}</span>}
        </div>
        <p className="text-red text-xs pr-3 h-4 mt-1">{error}</p>
      </div>
    );
  }
);

InputText.defaultProps = {
  wrapperClassName: "w-60",
};

export default InputText;
