import InputText from "global/InputText/InputText";
import { useSelector } from "react-redux";

interface OrderHeaderProps {
  formik?: any;
}

const OrderHeader = ({ formik }: OrderHeaderProps) => {
  const { orderManagementData } = useSelector((state: any) => state.orderData);

  return (
    <>
      <h1 className=" text-lg font-semibold">اطلاعات فرستنده</h1>
      <div className="flex-between-center max-w-6xl Max-md:flex-col gap-4 my-6">
        {!orderManagementData ? (
          <>
            <InputText
              label="نام و نام خانوادگی"
              name="sender.name"
              handleChange={formik?.handleChange}
              values={formik?.values.sender.name}
              important
              error={
                formik?.touched?.sender?.name && formik?.errors?.sender?.name
              }
              wrapperClassName="w-full"
            />
            <InputText
              label="کد ملی"
              name="sender.nationalCode"
              handleChange={formik?.handleChange}
              values={formik?.values.sender.nationalCode}
              important
              error={
                formik?.touched?.sender?.nationalCode &&
                formik?.errors?.sender?.nationalCode
              }
              wrapperClassName="w-full"
              maxLength={10}
            />
            <InputText
              label="موبایل"
              name="sender.mobile"
              handleChange={formik?.handleChange}
              values={formik?.values.sender.mobile}
              important
              error={
                formik?.touched?.sender?.mobile &&
                formik?.errors?.sender?.mobile
              }
              wrapperClassName="w-full"
              maxLength={11} 
            />
          </>
        ) : (
          <>
            <InputText
              label="نام و نام خانوادگی"
              name="sender.name"
              handleChange={formik?.handleChange}
              values={orderManagementData.name}
              readOnly
              wrapperClassName="w-full"
            />
            <InputText
              label="کد ملی"
              name="sender.nationalCode"
              handleChange={formik?.handleChange}
              values={orderManagementData.nationalCode}
              readOnly
              wrapperClassName="w-full"
            />
            <InputText
              label="موبایل"
              name="sender.mobile"
              handleChange={formik?.handleChange}
              values={orderManagementData.mobile}
              readOnly
              wrapperClassName="w-full"
            />
          </>
        )}
      </div>
    </>
  );
};

export default OrderHeader;
