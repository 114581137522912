import Checkbox from "components/checkbox/Checkbox";
import InputSelect from "global/InputSelect/InputSelect";
import InputText from "global/InputText/InputText";
import MultiLineText from "global/MultiLineText/MultiLineText";
import { useFetchOptions } from "global/hooks/useFetchOptions";
import { useState } from "react";
import { getUrls } from "services/api.enums";
import { searchByCity } from "tools/functions/Methods";
const Receiver = ({ formik }) => {
  const { values, setFieldValue, handleChange, errors, touched } = formik;
  const [disableSupport, setDisableSupport] = useState(true);
  const { dataOptions: contentType } = useFetchOptions(getUrls.findByCity);

  function handleChangeCity(name, value) {
    setFieldValue(name, value);
    const isSupport = contentType.options.find(
      (city) => city.id === value.id
    )?.boxitSupport;

    setFieldValue("thirdPartyDelivery", !isSupport);
    setDisableSupport(isSupport);
    if (!isSupport) {
      setFieldValue("cod", false);
      setFieldValue("postBack", false);
    }
  }

  return (
    <>
      <h1 className=" text-lg font-semibold">اطلاعات گیرنده</h1>
      <div className="grid grid-cols-3 items-center max-w-6xl Max-md:flex-col gap-x-4 gap-y-4 mt-6 mb-4 ">
        <InputText
          label="نام و نام خانوادگی"
          name="receiver.name"
          handleChange={handleChange}
          values={values.receiver.name}
          important
          error={touched?.receiver?.name && errors.receiver?.name}
          wrapperClassName="w-full"
          classNames="bg-white"
        />
        <InputText
          label="موبایل"
          name="receiver.mobile"
          handleChange={handleChange}
          values={values.receiver.mobile}
          important
          error={touched?.receiver?.mobile && errors?.receiver?.mobile}
          wrapperClassName="w-full"
          classNames="bg-white"
          maxLength={11}
        />

        <InputSelect
          label="شهر مقصد"
          important
          name="receiver.receiverCityId"
          handleChange={(name, value) => handleChangeCity(name, value)}
          values={values.receiver.receiverCityId}
          error={
            touched.receiver?.receiverCityId && errors.receiver?.receiverCityId
          }
          options={contentType.options}
          wrapperClassName="w-full"
          customFilter={searchByCity}
        />

        <MultiLineText
          label=" آدرس"
          important
          values={values.receiver.address}
          name="receiver.address"
          handleChange={handleChange}
          error={touched.receiver?.address && errors.receiver?.address}
          classNames="bg-white    "
          wrapperClassName="col-span-2"
          row={2}
        />
        <Checkbox
          isDisabled={disableSupport === false ?? true}
          WrapperClassName=""
          handleChange={(e) => {
            setFieldValue("thirdPartyDelivery", e.target.checked);
            setFieldValue("cod", false);
            setFieldValue("postBack", false);
          }}
          name="thirdPartyDelivery"
          values={values.thirdPartyDelivery}
          title="بخش تابعه، توزیع غیرباکسیت"
          labelClassName={!disableSupport ? " !text-red" : ""}
          className={!disableSupport ? " !text-red" : ""}
        />
      </div>
    </>
  );
};

export default Receiver;
