import { useEffect, useState } from "react";
import { ErrorAlert } from "../alert/Alert";
import { useSelector } from "react-redux";
import { filterDataAPI, getAPI } from "../../services/CRUDServices";
import { filterUrls, getUrls, selectUrls } from "../../services/api.enums";

export const useGetMainHub = () => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    filterDataAPI(selectUrls.MainSelectHub).then(({ data }: any) => {
      setOptions(data?.payload?.content || data?.payload);
    });
  }, []);
  return options;
};

export const useFetchHub = (
  hasAll?: boolean,
  open: boolean = true,
  accessHubList: boolean = true
) => {
  const [options, setOptions] = useState<any>([]);
  const [error, setError] = useState(false);
  const { hublist } = useSelector((state: any) => state.userInfo);
  const userHubs = useSelector((state: any) => state?.userInfo?.currenthub);
  const hub = accessHubList === true ? { hublist: hublist } : {};

  useEffect(() => {
    if (hublist?.length <= 0) return;
    try {
      filterDataAPI(selectUrls.selectHub, hub, true, false).then((res: any) => {
        // setOptions(res?.data?.payload?.content || res?.data?.payload);
        const response = res?.data?.payload?.content || res?.data?.payload;
        response.unshift({ id: userHubs?.id, text: userHubs?.label });
        hasAll && response.unshift({ id: null, text: "همه هاب ها" });
        const final = Array.from(new Set(response.map((a: any) => a.id))).map(
          (id) => {
            return response.find((a: any) => a.id === id);
          }
        );
        setOptions(final);
      });
    } catch (error: any) {
      setError(error);
      // ErrorAlert("دریافت دیتا با خطلا مواجه شد");
    }
  }, [hublist]);

  return { options, error };
};

export const useFetchOptions = (url: string, open: boolean = true) => {
  const [dataOptions, setOptions] = useState({
    options: [],
    error: "",
    loading: false,
  });

  useEffect(() => {
    try {
      setOptions({ ...dataOptions, loading: true });
      getAPI(url).then((res) => {
        let isOk = false;
        if (
          url === "consignment-api/driver/pickup/typesOfPackageList/" &&
          Array.isArray(res.data)
        ) {
          isOk = true;
        }
        if (res.data.status === "OK" || isOk) {
    
          setOptions({
            ...dataOptions,
            options:
              res.data?.payload?.content || res.data?.payload || res.data,
            loading: false,
          });
        }
      });
    } catch (error: any) {
      setOptions({ ...dataOptions, error: error, loading: false });
      ErrorAlert("دریافت دیتا با خطلا مواجه شد");
    }
  }, [url]);
  return { dataOptions };
};

export const useGetDisaptchShift = (data: any) => {
  const [dispatchShift, setDispatchShift] = useState([]);
  filterDataAPI(filterUrls.dispatchShift, data, true, false).then(
    (res: any) => {
      setDispatchShift(
        res.data.payload.map((shift: any) => ({
          id: shift.id,
          text:
            shift.timeFrom +
            " - " +
            shift.timeTo +
            " - " +
            shift.dispatchShiftType.text,
        }))
      );
    }
  );
  return dispatchShift;
};

export const useGetTripType = (open?: boolean) => {
  const [TripType, setTripType] = useState([]);
  useEffect(() => {
    getAPI(getUrls.triptype)
      .then(({ data }: any) => {
        setTripType(data.payload);
      })
      .catch(() => setTripType([]));
  }, []);
  return TripType;
};

export const useGetDriversByTrip = (filter: any, open?: boolean) => {
  const [Drivers, setDrivers] = useState([]);

  useEffect(() => {
    if (!open) return;
    getAPI(
      getUrls.driverByHub +
        `?filter=&hubId=${filter?.curenthub?.id || filter?.id}`
    )
      // filterDataAPI("consignment-api/driverplan/selectDriverByCreateTrip", filter, true, false)
      .then(({ data }: any) => {
        setDrivers(data.payload);
      });
  }, [filter, open]);
  return Drivers;
};

export const useFetchSampleOptions = (url: string, open: boolean = true) => {
  const [dataOptions, setOptions] = useState({
    options: [],
    error: "",
    loading: false,
  });

  useEffect(() => {
    try {
      setOptions({ ...dataOptions, loading: true });
      getAPI(url).then((res) => {
        setOptions({
          ...dataOptions,
          options: res.data,
          loading: false,
        });
      });
    } catch (error: any) {
      setOptions({ ...dataOptions, error: error, loading: false });
      ErrorAlert("دریافت دیتا با خطلا مواجه شد");
    }
  }, [url]);
  return { dataOptions };
};
