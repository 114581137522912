import React, { useState, useEffect } from "react";

const CountdownTimer = ({ initialTime }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  useEffect(() => {
    if (timeLeft <= 0) return;
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, [timeLeft, initialTime]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  useEffect(() => {
    setTimeLeft(initialTime);
  }, [initialTime]);

  return (
    <div className="flex  justify-between mt-5  [&>*]:font-bold">
      <p> زمان مورد نیاز جهت پرداخت</p>
      <p>{formatTime(timeLeft)}</p>
    </div>
  );
};

export default CountdownTimer;
