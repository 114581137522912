import Autocomplete from "react-autocomplete";
import React from "react";
import { FiSearch } from "react-icons/fi";

interface PropsData {
  items?: object[];
  value?: string | null;
  onSelect?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  wrapperClassName?: string;
  important?: boolean;
  error?: any;
  placeholder?: string;
  readOnly?: boolean;
  handleBlur?: any;
  handleFocus?: any;
  leftIcon?: any;
}

const AutocompleteInput = ({
  items = [],
  value,
  onSelect,
  onChange,
  leftIcon,
  label,
  important,
  wrapperClassName,
  error,
  placeholder,
  readOnly,
  handleBlur,
  handleFocus,
}: PropsData) => {
  return (
    <div className={`flex flex-col Max-md:w-full ${wrapperClassName}`}>
      <div
        className={`autocompleteWrapper bg-white ${error && "border-red"}  ${
          readOnly && "opacity-40"
        } `}
      >
        <div
          className={`autocompleteLabel  ${error && "text-red"} top-[-17px]`}
        >
          {label}{" "}
          <span className="text-tomato font-extrabold text-lg h-4">
            {important ? "*" : " "}
          </span>
        </div>
        <Autocomplete
          getItemValue={(item) => item?.label}
          items={items}
          renderItem={(item, isHighlighted, index) => (
            <div
              style={{
                padding: ".5rem",
                background: isHighlighted ? "lightgray" : "white",
              }}
            >
              {item?.label}
            </div>
          )}
          // This code destroy
          // shouldItemRender={(item, value) => {
          //   const splittedValue = value?.split(" ");
          //   const hasBeenFound = splittedValue?.some((text) => item?.label?.includes(text));
          //   return hasBeenFound;
          // }}
          value={value}
          onChange={onChange}
          onSelect={onSelect}
          menuStyle={{
            borderRadius: "3px",
            boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
            background: "rgba(255, 255, 255, 0.9)",
            maxHeight: "7rem",
            zIndex: "99",
            boxSizing: "border-box",
            fontSize: "90%",
            position: "absolute",
            left: "10px",
            right: 0,
            display: "flex",
            flexDirection: "column",
            top: "40px",
            overflow: "auto",
            cursor: "pointer",
            // maxHeight: '100%', // TODO: don't cheat, let it flow to the bottom
          }}
          wrapperStyle={{ width: "100%" }}
          inputProps={{
            className: "autocompleteInput",
            placeholder,
            disabled: readOnly,
            onBlur: handleBlur,
            onFocus: handleFocus,
          }}
        />
        {leftIcon ? leftIcon : <FiSearch size={19} className="text-darkGray" />}
      </div>
      <p className="text-red text-xs pr-3 h-4 mt-1">{error}</p>
    </div>
  );
};

AutocompleteInput.defaultProps = {
  wrapperClassName: "w-60",
};

export default AutocompleteInput;
