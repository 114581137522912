import { useState } from "react";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { links } from "../SidebarRoutes";
import { useNavigate } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";

import { hasPermission } from "utils/HasPermission";
import { clearFixedPaginate } from "redux/PaginationAction/PaginationAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";

const Sidebar = ({ setToggle }) => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [expanded, setIsExpanded] = useState(false);
  const { perms, isSuperAdmin } = useSelector((state: any) => state?.userInfo);

  const handleLogout = () => {
    localStorage.removeItem(process.env.REACT_APP_OIDC);
    localStorage.clear();
    auth.clearStaleState();
    auth.removeUser();
    window.location.href = process.env.REACT_APP_LOGOUT;
  };
  const dispatch = useDispatch();
  return (
    <>
      <SideNav
        expanded={expanded}
        onToggle={(expanded: boolean) => {
          setIsExpanded(expanded);
        }}
        style={{ right: "0", top: "0", bottom: "0", width: "50px" }}
        className="!bg-gray-900 focus:content-none"
        onSelect={(e: any) =>
          e !== undefined ? navigate(`/${e}`) : navigate(`/`)
        }
      >
        <img
          src={require("../../assets/images/Boxi-3.png")}
          alt="logo"
          className="mx-auto mt-5 mb-7 w-14 rounded-md"
        />
        {expanded ? (
          <span className="text-white block text-center">version : 1.0.10</span>
        ) : null}
        <SideNav.Toggle onClick={() => setToggle((last: boolean) => !last)} />
        <SideNav.Nav>
          {links.map((item, index) => {
            const { Icon, label, childs } = item;
            const childPerms = childs.map(({ permissions }) => permissions);
            let isPerm = false;
            if (isSuperAdmin) {
              isPerm = true;
            } else {
              isPerm = childPerms.some((item) => perms.includes(item));
            }
            return (
              isPerm && (
                <NavItem
                  key={index}
                  eventKey={label}
                  className="focus:border-none bg-[#121212]"
                >
                  <NavIcon>{Icon}</NavIcon>
                  <NavText>{label}</NavText>
                  {childs.map(({ to, label, permissions }, index) => {
                    return (
                      hasPermission(String(permissions)) && (
                        <NavItem
                          key={index}
                          eventKey={to.replace(/^\//, "")}
                          onClick={() => dispatch(clearFixedPaginate())}
                        >
                          <NavText
                            style={{
                              color: expanded ? "#eddede" : "#958c8c",
                              fontWeight: 500,
                              marginRight: expanded ? 0 : 20,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {label}
                          </NavText>
                        </NavItem>
                      )
                    );
                  })}
                </NavItem>
              )
            );
          })}
        </SideNav.Nav>
        <SideNav.Nav>
          <NavItem className="focus:border-none exit-item bg-[#121212]">
            <NavText className="border-none">
              <button onClick={handleLogout}>خروج</button>
            </NavText>
            <NavIcon>
              <button>
                <BiLogOut size={"25"} />
              </button>
            </NavIcon>

            <NavItem></NavItem>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    </>
  );
};

export default Sidebar;
