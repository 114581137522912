import React, { FC, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { SuccessAlert } from "../../global/alert/Alert";
import SimpleButton from "../../global/SimpleButton/SimpleButton";
import { deleteDataAPI } from "../../services/CRUDServices";
import Modal from "../../global/Modal/Modal";

interface DeleteOperationProps {
  title: string;
  itemId: number;
  route: string;
  handleDeleteActionNewData?: any;
  icon?:React.ReactNode
}
const DeleteOperation: FC<DeleteOperationProps> = ({
  title,
  itemId,
  route,
  handleDeleteActionNewData,
  icon
}): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const deleteHandler = async (id: number) => {
    try {
      await deleteDataAPI(route, itemId);
      SuccessAlert("با موفقیت حذف شد");
      setIsModalOpen(false);
      handleDeleteActionNewData && handleDeleteActionNewData();
    } catch (error) {
      setIsModalOpen(false);
      ErrorAlert("خطایی رخ داده است.");
    }
  };
  return (
    <>
      <button
        className=" border-none	text-[14px]  w-[20px] h-[20px]"
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
       {icon || <BiTrash size={20} className="w-full h-full	" />} 
      </button>
      <Modal visible={isModalOpen} setVisible={setIsModalOpen} title={title}>
        <p className="text-center">آیا از حذف این مورد اطمینان دارید؟</p>
        <div className="flex w-full justify-center gap-4 mt-5">
          <SimpleButton
            type="submit"
            text="خیر"
            className="full-lightTomato-btn w-28 "
            handelClick={() => setIsModalOpen(false)}
          />
          <SimpleButton
            type="submit"
            text="بله"
            className="full-tomato-btn w-28 "
            handelClick={() => deleteHandler(itemId)}
          />
        </div>
      </Modal>
    </>
  );
};

export default DeleteOperation;
function ErrorAlert(arg0: string) {
  throw new Error("Function not implemented.");
}
