import { useEffect,createContext, useState, FC, useContext } from "react";
import "react-tabs/style/react-tabs.css";
import "./styles/App.css";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import NotFound from "./pages/NotFound/NotFound";
import YupDefault from "./tools/config/YupDefault";
import GeoWrapperEdit from "./pages/CustomGeographic/views/editGeo/GeoWrapperEdit";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import DashboardLayout from "./components/Layout/DashboardLayout";
import NOPermission from "./pages/noPerms/NOPermission";
import StaticLinksPermissionRoute from "./utils/StaticLinksPermissionRoute";
import Login from "pages/Login/Login";
import Dashboard from "pages/Dashboard/Dashboard";
import OptimePanel from "pages/optimePanel";
import PermissionRoute from "utils/permissionRoutes";
import useClearPaginate from "utils/hooks/useClearPaginate";
import useSetAuthentication from "utils/hooks/useSetAuthentication";
import { useSelector } from "react-redux";
import AppContext, { I_CTX } from "app-context";
import BCC from "./components/bcc/bcc";
function App() {
  const userInfo = useSelector((state: any) => state.userInfo);
  const [bcc,setBcc] = useState<BCC>()
  //const [customeColumns] = useState<any>(getCustomColumns)
  // function getCustomColumns(){
  //   console.log(userInfo)    
  //   return {}
  // }
  useClearPaginate();
  useSetAuthentication();
  useEffect(() => {
    const location = window.location;
    const isCode = location.search.includes("code");
    const isState = location.search.includes("state");
    if (isCode && isState) {
      window.history.replaceState({}, document.title, "/");
    }

  }, []);
  function getContext():I_CTX {
    return {bcc,}
    // const userInfo = useSelector((state: any) => state.userInfo);
    // const customColumns = {}
    // return {userInfo,customColumns}
  }
  useEffect(()=>{
    if(userInfo.userinfo){
      const token = localStorage.getItem('token');
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const userId = userInfo.userinfo.id;
      const getUrl = `${baseUrl}/resource-api/customize/${userId}`
      const setUrl = `${baseUrl}/resource-api/customize`
      console.log(getUrl,setUrl)
      const inst = new BCC({
        config_dic: {
          consignment:{
            tableName:'مدیریت مرسوله',
            tableConfig:{
              grid: {
                getDefaultModel:(row) => ({active:true}),
                filter:(row)=>row.model.active !== false,
                idField: 'accessor',
                textField: 'header',
                inputs: [{ type: 'checkbox', field: 'active', text: 'نمایش ستون جدول', width: 200,def:true }],
                tabText: 'جدول مرسوله'
              },
              statusbar: {
                getDefaultModel:(row) => ({active:true}),
                filter:(row)=>row.model.active !== false,
                idField: 'id',
                textField: 'text',
                inputs: [{ type: 'checkbox', field: 'active', text: 'نمایش', width: 200,def:true }],
                tabText: 'نوار وضعیت'
              },
              filters: {
                idField: 'id',
                textField: 'label',
                onChange:(newRows)=>{
                  let fixedLength = 0;
                  for(let i = 0; i < newRows.length; i++){
                    const row = newRows[i]
                    fixedLength += row.model.type === 'fixed'?1:0
                    if(fixedLength > 4){
                      alert('حد مجاز تعداد فیلتر های ثابت 4 عدد است')
                      return false
                    }
                  }
                  return true
                },
                getDefaultModel:(row)=>{
                  if(row.id === '1' || row.id === '2' || row.id === '3'){return {type:'fixed'}}
                  return {type:'advanced'}
                },
                inputs: [
                  {
                    type: 'buttons', field: 'type', width: 300,def:'advanced',
                    setProps:(row)=>({disabled:row.id === '1'}),
                    options: [
                      { text: 'عدم نمایش', value: false },
                      { text: 'فیلتر پیشرفته', value: 'advanced' },
                      { text: 'فیلتر ثابت', value: 'fixed' }
                    ]
                  }
                ],
                tabText: 'فیلتر پیشرفته مرسوله'
              }
            }
          },
          vehicle:{
            tableName:'مدیریت نقلیه',
            tableConfig:{
              grid: {
                getDefaultModel:(row) => ({active:true}),
                filter:(row)=>row.model.active !== false,
                idField: 'accessor',
                textField: 'header',
                inputs: [{ type: 'checkbox', field: 'active', text: 'نمایش ستون جدول', width: 200,def:true }],
                tabText: 'جدول نقلیه'
              },
              filters: {
                idField: 'id',
                textField: 'label',
                onChange:(newRows)=>{
                  let fixedLength = 0;
                  for(let i = 0; i < newRows.length; i++){
                    const row = newRows[i]
                    fixedLength += row.model.type === 'fixed'?1:0
                    if(fixedLength > 4){
                      alert('حد مجاز تعداد فیلتر های ثابت 4 عدد است')
                      return false
                    }
                  }
                  return true
                },
                getDefaultModel:(row)=>{
                  if(row.id === '0000' || row.id === '0001'){return {type:'fixed'}}
                  return {type:'advanced'}
                },
                inputs: [
                  {
                    type: 'buttons', field: 'type', width: 300,def:'advanced',
                    setProps:(row)=>({disabled:row.id === '1'}),
                    options: [
                      { text: 'عدم نمایش', value: false },
                      { text: 'فیلتر پیشرفته', value: 'advanced' },
                      { text: 'فیلتر ثابت', value: 'fixed' }
                    ]
                  }
                ],
                tabText: 'فیلتر پیشرفته'
              }
            }
          },
          tripManagement:{
            tableName:'مدیریت سفر',
            tableConfig:{
              grid: {
                getDefaultModel:(row) => ({active:true}),
                filter:(row)=>row.model.active !== false,
                idField: 'accessor',
                textField: 'header',
                inputs: [{ type: 'checkbox', field: 'active', text: 'نمایش ستون جدول', width: 200,def:true }],
                tabText: 'ستون های جدول'
              },
              filters: {
                idField: 'id',
                textField: 'label',
                onChange:(newRows)=>{
                  let fixedLength = 0;
                  for(let i = 0; i < newRows.length; i++){
                    const row = newRows[i]
                    fixedLength += row.model.type === 'fixed'?1:0
                    if(fixedLength > 4){
                      alert('حد مجاز تعداد فیلتر های ثابت 4 عدد است')
                      return false
                    }
                  }
                  return true
                },
                getDefaultModel:(row)=>{
                  if(row.id === '0008'){return {type:'fixed'}}
                  return {type:'advanced'}
                },
                inputs: [
                  {
                    type: 'buttons', field: 'type', width: 300,def:'advanced',
                    options: [
                      { text: 'عدم نمایش', value: false },
                      { text: 'فیلتر پیشرفته', value: 'advanced' },
                      { text: 'فیلتر ثابت', value: 'fixed' }
                    ]
                  }
                ],
                tabText: 'فیلتر پیشرفته'
              }
            }
          },
          deliveryManagement:{
            tableName:'مدیریت تحویل',
            tableConfig:{
              grid: {
                getDefaultModel:(row) => ({active:true}),
                filter:(row)=>row.model.active !== false,
                idField: 'accessor',
                textField: 'header',
                inputs: [{ type: 'checkbox', field: 'active', text: 'نمایش ستون جدول', width: 200,def:true }],
                tabText: 'ستون های جدول'
              },
              filters: {
                idField: 'id',
                textField: 'label',
                onChange:(newRows)=>{
                  let fixedLength = 0;
                  for(let i = 0; i < newRows.length; i++){
                    const row = newRows[i]
                    fixedLength += row.model.type === 'fixed'?1:0
                    if(fixedLength > 4){
                      alert('حد مجاز تعداد فیلتر های ثابت 4 عدد است')
                      return false
                    }
                  }
                  return true
                },
                getDefaultModel:(row)=>{
                  if(row.id === '0001' || row.id === '0002' || row.id === '0003'){return {type:'fixed'}}
                  return {type:'advanced'}
                },
                inputs: [
                  {
                    type: 'buttons', field: 'type', width: 300,def:'advanced',
                    setProps:(row)=>({disabled:row.id === '0003'}),
                    options: [
                      { text: 'عدم نمایش', value: false },
                      { text: 'فیلتر پیشرفته', value: 'advanced' },
                      { text: 'فیلتر ثابت', value: 'fixed' }
                    ]
                  }
                ],
                tabText: 'فیلتر پیشرفته'
              }
            }
          },
          collectManagement:{
            tableName:'مدیریت جمع آوری',
            tableConfig:{
              grid: {
                getDefaultModel:(row) => ({active:true}),
                filter:(row)=>row.model.active !== false,
                idField: 'accessor',
                textField: 'header',
                inputs: [{ type: 'checkbox', field: 'active', text: 'نمایش ستون جدول', width: 200,def:true }],
                tabText: 'ستون های جدول'
              },
              filters: {
                idField: 'id',
                textField: 'label',
                onChange:(newRows)=>{
                  let fixedLength = 0;
                  for(let i = 0; i < newRows.length; i++){
                    const row = newRows[i]
                    fixedLength += row.model.type === 'fixed'?1:0
                    if(fixedLength > 4){
                      alert('حد مجاز تعداد فیلتر های ثابت 4 عدد است')
                      return false
                    }
                  }
                  return true
                },
                getDefaultModel:(row)=>{
                  return {type:'advanced'}
                },
                inputs: [
                  {
                    type: 'buttons', field: 'type', width: 300,def:'advanced',
                    options: [
                      { text: 'عدم نمایش', value: false },
                      { text: 'فیلتر پیشرفته', value: 'advanced' },
                      { text: 'فیلتر ثابت', value: 'fixed' }
                    ]
                  }
                ],
                tabText: 'فیلتر پیشرفته'
              }
            }
          },
          driverManagement:{
            tableName:'مدیریت رانندگان',
            tableConfig:{
              grid: {
                getDefaultModel:(row) => ({active:true}),
                filter:(row)=>row.model.active !== false,
                idField: 'accessor',
                textField: 'header',
                inputs: [{ type: 'checkbox', field: 'active', text: 'نمایش ستون جدول', width: 200,def:true }],
                tabText: 'ستون های جدول'
              },
              filters: {
                idField: 'valueName',
                textField: 'label',
                onChange:(newRows)=>{
                  let fixedLength = 0;
                  for(let i = 0; i < newRows.length; i++){
                    const row = newRows[i]
                    fixedLength += row.model.type === 'fixed'?1:0
                    if(fixedLength > 4){
                      alert('حد مجاز تعداد فیلتر های ثابت 4 عدد است')
                      return false
                    }
                  }
                  return true
                },
                getDefaultModel:(row:any)=>{
                  if(row.valueName === 'name' || row.valueName === 'selectHub'){return {type:'fixed'}}
                  return {type:'advanced'}
                },
                inputs: [
                  {
                    type: 'buttons', field: 'type', width: 300,def:'advanced',
                    setProps:(row)=>({disabled:row.id === 'selectHub'}),
                    options: [
                      { text: 'عدم نمایش', value: false },
                      { text: 'فیلتر پیشرفته', value: 'advanced' },
                      { text: 'فیلتر ثابت', value: 'fixed' }
                    ]
                  }
                ],
                tabText: 'فیلتر پیشرفته'
              }
            }
          },
          trips:{
            tableName:'فرم سفر ها',
            tableConfig:{
              grid: {
                getDefaultModel:(row) => ({active:true}),
                filter:(row)=>row.model.active !== false,
                idField: 'accessor',
                textField: 'header',
                inputs: [{ type: 'checkbox', field: 'active', text: 'نمایش ستون جدول', width: 200,def:true }],
                tabText: 'ستون های جدول'
              },
              filters: {
                idField: 'id',
                textField: 'label',
                onChange:(newRows)=>{
                  let fixedLength = 0;
                  for(let i = 0; i < newRows.length; i++){
                    const row = newRows[i]
                    fixedLength += row.model.type === 'fixed'?1:0
                    if(fixedLength > 4){
                      alert('حد مجاز تعداد فیلتر های ثابت 4 عدد است')
                      return false
                    }
                  }
                  return true
                },
                getDefaultModel:(row)=>{
                  if(row.id === '0002' || row.id === '0005'){return {type:'fixed'}}
                  return {type:'advanced'}
                },
                inputs: [
                  {
                    type: 'buttons', field: 'type', width: 300,def:'advanced',
                    // setProps:(row)=>({disabled:row.id === '0003'}),
                    options: [
                      { text: 'عدم نمایش', value: false },
                      { text: 'فیلتر پیشرفته', value: 'advanced' },
                      { text: 'فیلتر ثابت', value: 'fixed' }
                    ]
                  }
                ],
                tabText: 'فیلتر پیشرفته'
              }
            }
          }
        },
        onLoad:()=>setBcc(inst),
        getConfig:{
          description:'دریافت دیتای شخصی سازی',method:'get',url:getUrl,token,loading:false,
          errorResult:{},
          getResult:(response)=>{
            if(response.data.status === 'OK'){
              const res = response.data.payload.customize;
              return typeof res !== 'string'?{}:JSON.parse(res)
            }
          }
        },
        setConfig:(data)=>{
          return {
            description:'ویرایش دیتای شخصی سازی',method:'post',url:setUrl,token,
            errorResult:false,loading:false,
            body:{userId,customize:JSON.stringify(data)},
            getResult:(response)=>response.data.status === 'OK'
          }
        }
      })
    }
  },[JSON.stringify(userInfo)])
  return (
    <AppContext.Provider value={getContext()}>
      <div className="App">
        <YupDefault />
        <Routes>
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/" element={<DashboardLayout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/personalize" element={<CustomColumnsTable />} />
            <Route path="/optime/:id" element={<OptimePanel />} />
            {PermissionRoute()}
            {StaticLinksPermissionRoute()}
            <Route path="" element={<GeoWrapperEdit />} />
            <Route path="/access-denied" element={<NOPermission />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/Login" element={<Login />} />
          </Route>
        </Routes>
        {!!bcc && bcc.renderModal()}
      </div>
    </AppContext.Provider>
  );
}

export default App;
const CustomColumnsTable:FC = ()=>{
  const {customColumnsInstance} = useContext(AppContext);
  if(!customColumnsInstance){return null}
  return customColumnsInstance.render('consignment')
}