import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterDataAPI, filterTableDataAPI } from "services/CRUDServices";
import { filterUrls } from "services/api.enums";

export const filterPaymentDetailModel: AsyncThunk<{}, {}, {}> =
  createAsyncThunk("paymentDatail", async (body: any) => {
    const data = await filterDataAPI(
      filterUrls.paymentHistoryDetails,
      {
        ...body,
        pageNumber: undefined,
        pageSize: undefined,
        currentData:undefined,
        invoiceDto: body?.currentData?.invoiceDto,
        invoiceDetailList: body?.currentData?.invoiceDto?.invoiceDetails,
        orderDto: body?.currentData?.orderDto,
      },
      false,

    );

    return data.data;
  });

const initialState: any = {
  paymentDetailData: [],
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const PaymentDetailSlice = createSlice({
  initialState: initialState,
  name: "paymentDatail",
  reducers: {
    clearPaymentDetail: (state) => {
      state.paymentDetailData = [];
    },
    setFilterPaymentDetail: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [filterPaymentDetailModel.fulfilled as any]: (state, action) => {
      state.paymentDetailData = action.payload;
      state.fetchPost = false;
    },
    [filterPaymentDetailModel.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterPaymentDetailModel.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilterPaymentDetail, clearPaymentDetail } =
  PaymentDetailSlice.actions;
export default PaymentDetailSlice.reducer;
