import axios from "axios";
import { toast } from "react-toastify";
import { mainService } from "./MainService";
import { store } from "redux/store/Store";

export const getAPI = async (
  url: string,
  tokenRequired = true,
  sourceToken?: any
) => {
  const { token } = store.getState().authenticate;

  return await mainService({
    url: url,
    method: "GET",
    cancelToken: sourceToken,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const filterTableDataAPI = async (
  url: string,
  pageNumber: number,
  body: object,
  tokenRequired = true,
  pageSize?: number,
  hubRequired = true
) => {
  const { token } = store.getState().authenticate;
  const hublist = JSON.parse(localStorage.getItem("hublist") as any);
  return await mainService({
    url: url + `?pageNumber=${pageNumber}&pageSize=${pageSize || "10"}`,
    data: hubRequired ? { ...body, hublist } : body,
    method: "POST",
    headers: tokenRequired
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  });
};
export const filterDataAPI = async (
  url: string,
  body?: object,
  tokenRequired = true,
  hubRequired = true
) => {
  const hublist = JSON.parse(localStorage.getItem("hublist") as any);
  const { token } = store.getState().authenticate;
  return await mainService({
    url: url,
    data: hubRequired ? { ...body, hublist } : body,
    method: "post",
    headers: tokenRequired
      ? {
          //Authorization: `Bearer ${localStorage.getItem("myToken")}`,
          Authorization: `Bearer ${token}`,
        }
      : {},
  });
};

export const deleteDataAPI = async (
  url: string,
  id?: number | string,
  tokenRequired = true,
  body?: object
) => {
  const { token } = store.getState().authenticate;

  return await mainService({
    url: url + `/${id}`,
    method: "DELETE",
    headers: tokenRequired
      ? {
          //Authorization: `Bearer ${localStorage.getItem("myToken")}`,
          Authorization: `Bearer ${token}`,
        }
      : {},
    data: body,
  });
};

export const addEditDataAPI = async (
  url: string,
  method: "post" | "put",
  body: object,
  tokenRequired = true,
  hubRequired = false
) => {
  // const {token}=store.getState().auth
  const { token } = store.getState().authenticate;
  const hublist = JSON.parse(localStorage.getItem("hublist") as any);
  return await mainService({
    url: url,
    method,
    data: hubRequired ? { ...body, hublist } : body,

    headers: tokenRequired
      ? {
          //Authorization: `Bearer ${localStorage.getItem("myToken")}`,
          Authorization: `Bearer ${token}`,
        }
      : {},
  });
};

interface FetchAddressLocationProps {
  text: string;
  district?: string | number;
}
export const fetchAddressLocation = async ({
  text,
}: FetchAddressLocationProps) => {
  try {
    return await axios({
      method: "POST",
      url: "https://map.ir/search/v2",
      data: { text },
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_MAP_API_KEY,
      },
    });
  } catch (error) {
    toast.error("مشکلی در دریافت آدرس پیش آمده است ");
  }
};

export const fetchAddressLocationNew = async ({
  text,
  district = "1126",
}: FetchAddressLocationProps) => {
  try {
    return await axios({
      method: "GET",
      url: `https://api.parsimap.ir/geocode/address?key=p17629b8b76ae143a78ecc70946e02ee65ba0d2b6c&search_text=${text}&district=${district}&only_in_district=true&just_in_district=true&subdivision=true&plate=true&request_id=true`,
    });
  } catch (error) {
    toast.error("مشکلی در دریافت آدرس پیش آمده است ");
  }
};

interface FetchSearchAddressProps {
  lat: number;
  long: number;
}
export const fetchSearchAddress = async ({
  lat,
  long,
}: FetchSearchAddressProps) => {
  try {
    return await axios({
      method: "GET",
      url: `https://map.ir/reverse?lat=${lat}&lon=${long}`,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_MAP_API_KEY,
      },
    });
  } catch (error) {
    toast.error("مشکلی در دریافت آدرس پیش آمده است ");
  }
};
export const fetchSearchAddressNew = async ({
  lat,
  long,
}: FetchSearchAddressProps) => {
  try {
    return await axios({
      method: "GET",
      url: `https://api.parsimap.ir/geocode/reverse?key=p17629b8b76ae143a78ecc70946e02ee65ba0d2b6c&location=${long},${lat}&local_address=true&approx_address=false&subdivision=false&plate=false&request_id=false&just_in_district=true`,
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    toast.error("مشکلی در دریافت آدرس پیش آمده است ");
  }
};
