import DragableMultiMarker from 'components/map/DragableMultiMarker';
import { memo } from 'react';
import { ImLocation } from 'react-icons/im';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useDispatch } from 'react-redux';
import { ActionSelectPins, changeSelectTab } from 'redux/optime/optime';

const RenderPins=({pins}:any)=> {
  const dispatch=useDispatch()
  return (
    pins &&
        pins.polygons?.length !== 0 &&
        pins.polygons.map((item: any) => (
          <MarkerClusterGroup chunkedLoading>
            {item.consignmnetList.map((pin) => (
              <DragableMultiMarker
                tooltip={pin.CustomerName}
                position={{ lat: pin.latitude, lng: pin.longitude }}
                onClickAction={() => {
                  dispatch(changeSelectTab(1));
                  dispatch(ActionSelectPins({ ...pin, ...item }));
                }}
                markerIcon={
                  <ImLocation
                    className={`bg-none  border-none absolute top-[-10px]   left-[-14px] bottom-0 m-auto z-0`}
                    size={"40px"}
                    style={{
                      fill: `${item.color}`,
                      stroke: "black",
                      strokeWidth: "0.8",
                    }}
                  />
                }
              />
            ))}
          </MarkerClusterGroup>
        ))
  )
}

export default memo(RenderPins)